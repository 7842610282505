declare global {
  interface Window {
    dataLayer: any;
  }
}

interface GoogleTagManagerEvent {
  event: 'buttonClick'
  category: 'kom'
  action: string
  label: string
}

interface GoogleTagManagerUserId {
  userId: string
}

export const googleTag = {
  event: ({
    event,
    category,
    action,
    label,
  }:GoogleTagManagerEvent) => {
    if (process.env.NODE_ENV === 'development') {
      return
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event,
      category,
      action,
      label,
    })
  },
  userId: ({ userId }: GoogleTagManagerUserId) => {
    if (process.env.NODE_ENV === 'development') {
      return
    }

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'login', 
      userId,
    })
  },
}