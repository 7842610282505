import { motion } from 'framer-motion'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { rgba } from 'polished'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import { LoginForm } from './components/LoginForm/LoginForm'
import MenuLink from './components/MenuLink'
// import { RegisterForm } from './components/RegisterForm'
import BackgroundVideo from './components/BackgroundVideo'
import config from '../../../config'
import { mediaMax } from '../../constants/breakpoints'
import { Locale } from '../../constants/locale/locale'
import { useMenuDispatch } from '../../providers/menuProvider'
import { theme } from '../../styles/theme'
import { LISAFrontPage } from '../../types/lisa'
import { User } from '../../utils/auth'
import MenuSeparator from '../Separator/_Separator'
import { MicIconRatio as SVGMicIconRatio } from '../Svg/MicIcon'
import { PanoramaIcon as SVGPanoramaIcon } from '../Svg/Panorama'

type LanguageSpecific = {
  textLoggedOut: string
  textLoggedIn: string
  logo: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lobbyMenuPrograms: any[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lobbyMenuRooms: any[]
}
interface LobbyProps extends LISAFrontPage {
  user: User
}

const Lobby: React.FC<LobbyProps> = ({
  textNotLoggedIn = 'empty',
  textNotLoggedInEN = 'empty',
  text = 'empty',
  textEN = 'empty',
  user,
}: LobbyProps) => {
  const { t } = useTranslation('common')
  const conferenceOpen = true

  const menuDispatch = useMenuDispatch()
  const isAuthenticated = !!user

  const [MobileToggle, setMobileToggle] = useState<'Program'| 'Room'>('Room')

  const handleContactUs = () => {
    menuDispatch({ type: 'CONTACT', value: true })
  }

  const { locale } = useRouter()
  // TODO: define default state values instead

  const languageSpecific = useMemo<LanguageSpecific>(() => {
    if(locale === Locale.is) {
      return {
        textLoggedOut: textNotLoggedIn,
        textLoggedIn: text,
        logo: config.LOGO,
        lobbyMenuPrograms: config.LOBBY_MENU_PROGRAMS,
        lobbyMenuRooms: config.LOBBY_MENU_ROOMS,
      }
    }
    if(locale === Locale.en) {
      return {
        textLoggedOut: textNotLoggedInEN,
        textLoggedIn: textEN,
        logo: config.LOGO_EN,
        lobbyMenuPrograms: config.LOBBY_MENU_PROGRAMS_EN,
        lobbyMenuRooms: config.LOBBY_MENU_ROOMS_EN,
      }
    }
    return {
      textLoggedOut: '',
      textLoggedIn: '',
      logo: '',
      lobbyMenuPrograms: [],
      lobbyMenuRooms: [],
    }
  }, [locale, text, textEN, textNotLoggedIn, textNotLoggedInEN])

  const backgroundSrc = { src: '/Haf_4sek_med_ColorCorrection.mp4', type: 'video/mp4' }

  // const handleRegisterFormSuccess = () => {
  //   // TODO: no cleanup
  //   setTimeout(() => {
  //     setShowRegistration(false)
  //   }, 2500)
  // }

  const roomsMenu = () => {
    if (!conferenceOpen) {return null}

    return (
      <MenuSeparator
        title={t('rooms')}
        width="50%"
        showColor={false}
        lineColor={theme.colors.secondary.base}
        showInMobile={MobileToggle === 'Room' ? true : false}
        isButton={false}
      >
        <nav>
          {languageSpecific.lobbyMenuRooms.map((room, index): React.ReactNode => {
            if (user && room.authAllowed.indexOf(user.role) !== -1) {
              return (
              // eslint-disable-next-line react/no-array-index-key
                <MenuLink key={index} href={room.link} openInNewTab={room.openInNewTab}>
                  {room.title}
                </MenuLink>
              )
            }
            return null
          })}
        </nav>
      </MenuSeparator>
    )
  }

  return (
    <LobbyWrapper>
      <BackgroundVideo source={backgroundSrc} loop={true} />
      {/* <ImageContainer>
        <Image
          quality={50}
          layout="fill"
          src={config.BACKGROUND_image_lobby}
          objectFit="cover"
          objectPosition="center"
          alt="Background image for lobby page"
        />
      </ImageContainer> */}
      <BackgroundGradient />
      <Content>
        <ContainerLogo>
          {/* TODO: These are svg icons. Why use them as images */}
          <MainLogo>
            <Image
              src={languageSpecific.logo}
              alt="logo"
              width={444}
              height={359}
              priority
            />
          </MainLogo>
          {/* <MainLogoImg src={languageSpecific.logo} alt="Logo" /> */}
          {/* <Logo fill="#fff" width="80%"/> */}
        </ContainerLogo>

        <ContainerMenu>
          <MenuSeparator
            title={languageSpecific.lobbyMenuPrograms.length > 0 ? t('programs') : t('welcome')}
            width="50%"
            margin="2.5%"
            showColor={false}
            lineColor={theme.colors.fourth.base}
            showInMobile={MobileToggle === 'Program' && isAuthenticated ? true : false}
            isButton={false}
          >
            {isAuthenticated && languageSpecific.lobbyMenuPrograms.length > 0 && (
              <nav>
                {languageSpecific.lobbyMenuPrograms.map((room, index): React.ReactNode => {
                  if (user && room.authAllowed.indexOf(user.role) !== -1) {
                    return (
                    // eslint-disable-next-line react/no-array-index-key
                      <MenuLink key={index} href={room.link} openInNewTab={room.openInNewTab}>
                        {room.title}
                      </MenuLink>
                    )
                  }
                  return null
                })}
              </nav>
            )}
            {isAuthenticated && languageSpecific.lobbyMenuPrograms.length === 0 && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: languageSpecific.textLoggedIn,
                }}
              />
            )}

            {!isAuthenticated && languageSpecific.lobbyMenuPrograms.length === 0 && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: languageSpecific.textLoggedOut,
                }}
              />
            )}
          </MenuSeparator>
          {!isAuthenticated ? (
            <MenuSeparator title={t('login')} width="48%" showInMobile>
              <LoginForm onContactUs={handleContactUs} />

              {/* <ButtonWrapper>
                <RegistrationButton 
                  type="button" 
                  onClick={() => setShowRegistration(true)}
                >
                  {t('register.register')}
                </RegistrationButton>
              </ButtonWrapper> */}


              {/* <RegisterForm 
                active={showRegistration}
                onClose={() => setShowRegistration(false)}
                onSuccess={handleRegisterFormSuccess}
              /> */}
              
            </MenuSeparator>
          ) : (
            roomsMenu()
          )}
        </ContainerMenu>
      </Content>
      {/* dont show lobby buttons in mobile if we don't have a program menu on the left */}
      {isAuthenticated && languageSpecific.lobbyMenuPrograms.length > 0 &&
        <LobbyContainer>
          <LobbyButton
            type="button"
            className={MobileToggle === 'Program' ? 'active' : ''}
            id="button-program"
            onClick={() => setMobileToggle('Program')}
          >
            <IconDiv>
              <SVGMicIconRatio width="100%" className="menu-toggle-button" fill={MobileToggle === 'Program' ? 'Black' : 'White'} />
            </IconDiv>
            {t('program')}
          </LobbyButton>
          <LobbyButton
            type="button"
            className={MobileToggle === 'Room' ? 'active': ''}
            id="button-rooms"
            onClick={() => setMobileToggle('Room')}
          >
            <IconDiv>
              <SVGPanoramaIcon width="100%" className="menu-toggle-button" fill={MobileToggle === 'Room' ? 'Black' : 'White'} />
            </IconDiv>
            <ButtonText>

              {t('rooms')}
            </ButtonText>
          </LobbyButton>
        </LobbyContainer>
      }

      
    </LobbyWrapper>
  )
}

export default Lobby

const MainLogoImg = styled.img`
  max-height: 100%;
  width: auto;
  max-width:55%;
  
  @media ${mediaMax.mobileL} {
    max-width:80%;
  }
  @media ${mediaMax.mobileM} {
    max-width:100%;
  }
  
  @media ${mediaMax.tabletL}{
    max-width: none;
  }
`

const LobbyContainer = styled.div`
  display: none;
  
  @media ${mediaMax.tabletL} {
    position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
`

const ButtonText = styled.div`
  display: inline;
`

const IconDiv = styled.div`
  margin-right: max(8px, 3.733333333333334vw);
  width: 24px;
  height: 24px;
`

const LobbyButton = styled.button`
  display: none;

  @media ${mediaMax.mobileL} {
    margin-bottom: 50px;
    background: rgba(255,255,255,0.15);
    border-radius: 25px;
    max-width: max(162px, 16.6666vw);
    font-family: ${theme.fonts.fontFamilySecondary};
    font-weight: bold;
    font-size: max(14px, 0.9722vw);
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding-top: 3px;
    height: max(45px, 3.125vw);
    border: none;
    width: 100%;
    color: #ffffff;

    &.active {
      background: white;
      color: black;
    }
    
    &:focus {
      outline: none;
    }
  }
`

const RegisterTicketWrapper = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: fixed;
  top:0;
  left:0;
`

const ButtonWrapper = styled.div`
  width: 100%;
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top:10px;
`

const RegistrationButton = styled.button`
  background: transparent;
  border:none;
  color: white;
  font-size: max(0.7vw, 18px);
  text-decoration:underline;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  cursor:pointer;
`

const MainLogo = styled.div`
  position: relative;
  /* width:55%; */
  
  /* @media ${mediaMax.mobileL} {
    width:80%;
  }

  @media ${mediaMax.mobileM} {
    width:100%;
  } */
`

const ContainerMenu = styled.div`
  width: 81vw;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  @media ${mediaMax.tablet} {
    justify-content: center;
  }

  @media ${mediaMax.mobileL} {
    width: 100%;
    padding: 0 max(20px, 4.705882352941vw);
  }
`

const LobbyWrapper = styled.div`
  height: 100%;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
`

const Content = styled.div`
  width: 100%;
  padding: 80px 0;
`

const Text = styled.div`
  font-style: normal;
  font-weight: bold;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  color: ${({ theme }) => theme.colors.white};

  font-size: max(1.45833vw, 21px);
  line-height: 161.905%;
  padding-right: 3.54167vw;
`

const BackgroundGradient = styled.div`
  z-index: -1;
  height: 100%;
  /* height: 100vh; */
  width: 100%;
  position: fixed;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(0deg, ${rgba('#000', 0.4)} 45%, ${rgba('#000', 0.5)} 73%);
`

const ImageContainer = styled.div`
  z-index: -1;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`

const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;

  @media ${mediaMax.mobileL} {
    margin-bottom: 40px;
  }
`
