import router from 'next/router'
import { rgba } from 'polished'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'
import * as Sentry from '@sentry/browser'
import { locators } from './locators'
import { mediaMax } from '../../../../constants/breakpoints'
import { EnumLoginErrorLabel } from '../../../../pages/api/lib/login/constants'
import { googleTag } from '../../../../utils/googleTagManager'

type GeneralError = {
  type: 'notRegistered' | 'general'
  message: string
}

type IFormInput = {
  email: string
  setOnPostList: boolean
  type: string
}

interface LoginFormProps {
  onContactUs: () => void
}
export const LoginForm: React.FC<LoginFormProps> = ({
  onContactUs,
}) => {
  const { t } = useTranslation('common')
  const [generalError, setGeneralError] = useState<GeneralError | null>(null)

  const { register, handleSubmit, formState: { errors } } = useForm<IFormInput>()
  const [loading, setLoading] = useState(false)

  const loginSubmit = (formData: IFormInput) => {
    setGeneralError(null)
    setLoading(true)
    fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: formData.email }),
    })
      .then((res) => {
        if (res.json && (res.status === 200 || res.status === 400)) {
          return res.json()
        }
        throw new Error()
      })
      .then((data) => {
        // Handled errors
        if (data.error) {
          if (data.error === EnumLoginErrorLabel.EmailInvalid) {
            setGeneralError({ type: 'general', message: t('generalError') })
          }
          else if (data.error === EnumLoginErrorLabel.NotRegistered) {
            setGeneralError({ type: 'notRegistered', message: t('form.errorServerNotRegistered') })
          }
        }
        // Successful login
        else {
          //this only works with prisma -> need another id for this to work
          if(data.user && data.user.id) {
            // Register success we notify google tag manager
            googleTag.userId({ userId: data.user.id })
            localStorage.setItem('u_cuid', data.user.id)
            
            //connect the user to the sentry configurations for better error handling
            Sentry.setUser({ email: formData.email })
          }
          
          // TODO: Remove
          setTimeout(() => {
            router.reload()
          }, 500)
        }
      })
      .catch((error) => {
        setGeneralError({ type: 'general', message: t('generalError') })
        throw Error(`API login fetch caught an error, ${error}, trying to log in ${formData.email}`)
      })
  }

  return (
    <FormContent className="active">
      <Form
        noValidate
        onSubmit={handleSubmit(loginSubmit)}
      >
        {(generalError && generalError.type === 'general') && (
          <StyledGeneralError id={locators.errorServerGeneral}>
            {generalError.message}
            {' '}
            Please try again or
            <button type="button" onClick={onContactUs} className="button-try-again">contact us</button>
          </StyledGeneralError>
        )}
        <Input
          id="email"
          className={`input ${(generalError && generalError.type === 'notRegistered') ? 'error' : ''}`}
          placeholder={t('form.placeholderEmail')}
          type="email"
          {...register(
            'email',
            {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('form.formEmailInvalid'),
              },
            },
          )}
        />
        {(generalError && generalError.type === 'notRegistered') && (
          <StyledGeneralError id={locators.errorServerNotRegistered}>
            {t('form.errorServerNotRegistered')}
          </StyledGeneralError>
        )}
        {errors.email && errors.email.type === 'required' && (
          <SpanError id={locators.errorReq}>{t('form.formRequiredEmail')}</SpanError>
        )}
        {errors.email && errors.email.type === 'pattern' && (
          <SpanError id={locators.errorPattern}>{t('form.formEmailInvalid')}</SpanError>
        )}

        <ButtonNormal id={locators.submitButton} type="submit" className={loading ? 'disabled' : ''}>
          {t('loginButton')}
        </ButtonNormal>
      </Form>
    </FormContent>
  )
}

export const Input = styled.input`
  width: 100%;
  background-color: rgb(255, 255, 255);
  letter-spacing: 0;
  border: none;
  
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  
  border-radius: max(22.5px, 1.73077vw);
  height: max(45px, 3.125vw);
  padding-left: max(20px, 1.388888888889vw);
  font-size: max(16px, 1.11111112vw);
  line-height: 1em;
  margin-bottom: max(10px, 0.69444444vw);
  
  &.error {
    border: 2px solid #F03434;
    margin-bottom: 0;
  }

  &:focus {
    outline: none;
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #808080;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    letter-spacing: 0;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #808080;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    letter-spacing: 0;
  }

  ::placeholder {
    color: #808080;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    letter-spacing: 0;
  }
`

const FormContent = styled.div`
  opacity: 0;
  top: 50px;
  z-index: -1;
  transition: opacity 0.3s ease-in;
  &.active {
    transition: opacity 0.3s ease-in 0.3s;
    opacity: 1;
    z-index: 2;
  }
  &.hidden {
    display: none;
  }
  @media ${mediaMax.mobileL} {
    width: 100%;
  }
`

const StyledGeneralError = styled.div`
  color: #F03434;
  padding-left: 5%;
  padding-bottom: 8px;

  .button-try-again {
    margin: 0;
    padding: 0;
    text-decoration: underline;
    background: none;
    border-radius: 0;
    border: 0;
    font-size: 100%;
    display: block;
    cursor: pointer;
  }
`

const SpanError = styled.span`
  display: inline-block;
  color: red;
  font-family: ${({ theme }) => theme.fonts.fontFamilyPrimary};
  font-size: max(16px, 1.11111112vw);
  margin-bottom: max(10px, 0.69444444vw);
`

const Form = styled.form`
  position: relative;
`

const ButtonNormal = styled.button`
  width: 100%;
  font-weight: bold;
  letter-spacing: 0;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  // eslint-disable-next-line max-len
  background: ${({ theme }) => (
    `linear-gradient(
      270deg, 
      ${rgba(theme.colors.primary.base, 1)} 45%, 
      ${rgba(theme.colors.primary.base, 0.7)} 73%
    )`
  )};
  height: max(45px, 3.125vw);
  border-radius: 1.73077em;
  /* line-height: 28px; */
  font-size: max(18px, 1.25vw);

  &:focus,
  &:active {
    outline: none;
  }

  &.disabled{
    background: grey;
    color: rgba(255,255,255,0.4);
    pointer-events:none;
  }
`
